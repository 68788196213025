import {
  AlignmentType,
  Document,
  HeadingLevel,
  Packer,
  Paragraph,
  TabStopPosition,
  TabStopType,
  TextRun
} from "docx";
const PHONE_NUMBER = "07534563401";
const PROFILE_URL = "https://www.linkedin.com/in/dolan1";
const EMAIL = `docx@docx.com`;

export class DocumentCreator {
  // tslint:disable-next-line: typedef
  /**
   *
   * @param experiences
   * @param educations
   * @param skills
   * @param achivements
   * @returns Document
   */
  create([experiences, educations, skills, achivements]) {
    const document = new Document({
      sections: [
        {
          children: [
            new Paragraph({
              text: "Dolan Miu",
              heading: HeadingLevel.TITLE
            }),
            DocumentCreator.createContactInfo(PHONE_NUMBER, PROFILE_URL, EMAIL),
            this.createHeading("Education"),
            ...educations
                .map(education => {
                  // const arr: Paragraph[] = [];
                  const arr = [];
                  arr.push(
                      this.createInstitutionHeader(
                          education.schoolName,
                          `${education.startDate.year} - ${education.endDate.year}`
                      )
                  );
                  arr.push(
                      this.createRoleText(
                          `${education.fieldOfStudy} - ${education.degree}`
                      )
                  );

                  const bulletPoints = this.splitParagraphIntoBullets(
                      education.notes
                  );
                  bulletPoints.forEach(bulletPoint => {
                    arr.push(this.createBullet(bulletPoint));
                  });

                  return arr;
                })
                .reduce((prev, curr) => prev.concat(curr), []),
            this.createHeading("Experience"),
            ...experiences
                .map(position => {
                  const arr = [];

                  arr.push(
                      this.createInstitutionHeader(
                          position.company.name,
                          this.createPositionDateText(
                              position.startDate,
                              position.endDate,
                              position.isCurrent
                          )
                      )
                  );
                  arr.push(this.createRoleText(position.title));

                  const bulletPoints = this.splitParagraphIntoBullets(
                      position.summary
                  );

                  bulletPoints.forEach(bulletPoint => {
                    arr.push(this.createBullet(bulletPoint));
                  });

                  return arr;
                })
                .reduce((prev, curr) => prev.concat(curr), []),
            this.createHeading("Skills, Achievements and Interests"),
            this.createSubHeading("Skills"),
            this.createSkillList(skills),
            this.createSubHeading("Achievements"),
            ...this.createAchivementsList(achivements),
            this.createSubHeading("Interests"),
            this.createInterests(
                "Programming, Technology, Music Production, Web Design, 3D Modelling, Dancing."
            ),
            this.createHeading("References"),
            new Paragraph(
                "Dr. Dean Mohamedally Director of Postgraduate Studies Department of Computer Science, University College London Malet Place, Bloomsbury, London WC1E d.mohamedally@ucl.ac.uk"
            ),
            new Paragraph("More references upon request"),
            new Paragraph({
              text:
                  "This CV was generated in real-time based on my Linked-In profile from my personal website www.dolan.bio.",
              alignment: AlignmentType.CENTER
            })
          ]
        }
      ]
    });

    return document;
  }

  /**
   *
   * @param phoneNumber
   * @param profileUrl
   * @param email
   * @returns {Paragraph}
   */
  static createContactInfo(
      phoneNumber = '',
      profileUrl = '',
      email = ''
  ) {
    return new Paragraph({
      alignment: AlignmentType.CENTER,
      children: [
        new TextRun(
            `Mobile: ${phoneNumber} | LinkedIn: ${profileUrl} | Email: ${email}`
        ),
        new TextRun({
          text: "Address: 58 Elm Avenue, Kent ME4 6ER, UK",
          break: 1
        })
      ]
    });
  }

  /**
   *
   * @param text
   * @returns {Paragraph}
   */
  createHeading(text = '') {
    return new Paragraph({
      text: text,
      heading: HeadingLevel.HEADING_1,
      thematicBreak: true
    });
  }

  /**
   *
   * @param text
   * @returns {Paragraph}
   */
  createSubHeading(text = '') {
    return new Paragraph({
      text: text,
      heading: HeadingLevel.HEADING_2
    });
  }

  /**
   *
   * @param institutionName
   * @param dateText
   * @returns {Paragraph}
   */
  createInstitutionHeader(
      institutionName = '',
      dateText = ''
  ) {
    return new Paragraph({
      tabStops: [
        {
          type: TabStopType.RIGHT,
          position: TabStopPosition.MAX
        }
      ],
      children: [
        new TextRun({
          text: institutionName,
          bold: true
        }),
        new TextRun({
          text: `\t${dateText}`,
          bold: true
        })
      ]
    });
  }

  /**
   *
   * @param roleText
   * @returns {Paragraph}
   */
  createRoleText(roleText) {
    return new Paragraph({
      children: [
        new TextRun({
          text: roleText,
          italics: true
        })
      ]
    });
  }

  /**
   *
   * @param text
   * @returns {Paragraph}
   */
  createBullet(text) {
    return new Paragraph({
      text: text,
      bullet: {
        level: 0
      }
    });
  }

  // tslint:disable-next-line:no-any
  /**
   *
   * @param skills
   * @returns {Paragraph}
   */
  createSkillList(skills = []) {
    return new Paragraph({
      children: [new TextRun(skills.map(skill => skill.name).join(", ") + ".")]
    });
  }

  // tslint:disable-next-line:no-any
  /**
   *
   * @param achivements
   * @returns {Paragraph[]}
   */
  createAchivementsList(achivements = []) {
    return achivements.map(
        achievement =>
            new Paragraph({
              text: achievement.name,
              bullet: {
                level: 0
              }
            })
    );
  }

  /**
   *
   * @param interests
   * @returns {Paragraph}
   */
  createInterests(interests) {
    return new Paragraph({
      children: [new TextRun(interests)]
    });
  }

  /**
   *
   * @param text
   * @returns {*}
   */
  splitParagraphIntoBullets(text){
    return text.split("\n\n");
  }

  // tslint:disable-next-line:no-any
  /**
   *
   * @param startDate
   * @param endDate
   * @param isCurrent
   * @returns {string}
   */
  createPositionDateText(
      startDate = null,
      endDate = null,
      isCurrent = false
  ) {
    const startDateText =
        this.getMonthFromInt(startDate.month) + ". " + startDate.year;
    const endDateText = isCurrent
        ? "Present"
        : `${this.getMonthFromInt(endDate.month)}. ${endDate.year}`;

    return `${startDateText} - ${endDateText}`;
  }

  /**
   *
   * @param value
   * @returns {string}
   */
  getMonthFromInt(value) {
    switch (value) {
      case 1:
        return "Jan";
      case 2:
        return "Feb";
      case 3:
        return "Mar";
      case 4:
        return "Apr";
      case 5:
        return "May";
      case 6:
        return "Jun";
      case 7:
        return "Jul";
      case 8:
        return "Aug";
      case 9:
        return "Sept";
      case 10:
        return "Oct";
      case 11:
        return "Nov";
      case 12:
        return "Dec";
      default:
        return "N/A";
    }
  }
}
