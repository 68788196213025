<template>
  <div class="page flex-col">
    <!--    <el-button @click="generate">Generate my CV with docx!</el-button>-->

    <el-card header="cos直传">
      {{ fileList }}
      <el-upload
        :accept="acceptList"
        :auto-upload="true"
        :before-upload="beforeUpload"
        :file-list="fileList"
        :http-request="customUpload"
        :multiple="false"
        :on-change="afterInputFiles"
        :on-remove="handleRemove"
        action="#"
        drag
        ref="uploadArea"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">
          Drop file here or <em>click to upload</em>
        </div>
        <div class="el-upload__tip" slot="tip">a video only</div>
      </el-upload>
    </el-card>
    <el-card header="jszip压缩包下载">
      <el-button :loading="loading" @click="downloadZip">点击下载压缩包</el-button>
    </el-card>
  </div>
</template>

<script>
import { DocumentCreator } from '../js/cv-generator'
import { achievements, education, experiences, skills } from '../js/cv-data'
import { Packer } from 'docx'

import { saveAs } from 'file-saver'
import api from '../../../base/utils/request'

import JSZip from 'jszip' // API 文档：http://docs.asprain.cn/jszip/jszip.html#jszip_generate_async

export default {
  data() {
    const types = ['ogg', 'mpeg4', 'webm', 'mp4', 'flv']

    return {
      fileList: [],
      acceptList: types.map((t) => `video/${t}`).join(','),

      cosReady: false,
      loading: false,
    }
  },
  methods: {
    downloadZip() {
      this.loading = true
      const zip = new JSZip() // 利用这个来构造压缩包的内容以提供下载
      const folder = zip.folder('folder') // 创建文件夹，该文件夹作为解压后得到的根目录文件夹
      folder.file(`123.txt`, '123') // 向文件夹中新增文件并在文件中写入内容
      zip.generateAsync({ type: 'blob' }).then(function (blob) {
        saveAs(blob, `my-zip.zip`) // 触发浏览器下载操作
        this.loading = false
      }) // 打包：在当前的文件夹级别生成一个完整的Zip文件。
    },
    generate() {
      const documentCreator = new DocumentCreator()
      const doc = documentCreator.create([
        experiences,
        education,
        skills,
        achievements,
      ])

      Packer.toBlob(doc).then((blob) => {
        console.log(blob)
        saveAs(blob, `example.docx`)
        console.log('Document created successfully')
      })
    },

    fetchCosConfiguration(callback) {
      this.currentFile = this.fileList[0]
      this.currentType = 'video'

      // 一个文件对应一次请求
      api({
        url: '/admin/admin/media/uploadCosTemporaryAuth',
        data: {
          type: this.currentType,
          file_name: this.currentFile.name,
        },
        method: 'post',
      })
        .then(({ data }) => {
          this.data = data

          callback(data)
        })
        .catch(() => {})
    },

    readyForCos(
      {
        bucket_id,
        region,
        tmp_id,
        tmp_key,
        tmp_token,
        expire_in,
        title,
        dir,
        file_ext,
        key,
        protocal,
        domain,
      } = {},
      callback = () => {}
    ) {
      let that = this

      try {
        // 存储桶名称，由 bucketname-appid 组成，appid 必须填入，可以在 COS 控制台查看存储桶名称。 https://console.cloud.tencent.com/cos5/bucket
        const Bucket = bucket_id /* 存储桶，必须字段 */

        // 存储桶 region 可以在 COS 控制台指定存储桶的概览页查看 https://console.cloud.tencent.com/cos5/bucket/
        // 关于地域的详情见 https://cloud.tencent.com/document/product/436/6224
        const Region = region /* 存储桶所在地域，必须字段 */

        // 初始化实例
        const cos = new COS({
          Protocol: protocal,
          Domain: domain.replace('https://', ''),
          // getAuthorization 必选参数
          getAuthorization: function (options, callback) {
            // 初始化时不会调用，只有调用 cos 方法（例如 cos.putObject）时才会进入
            // 异步获取临时密钥
            // 服务端 JS 和 PHP 例子：https://github.com/tencentyun/cos-js-sdk-v5/blob/master/server/
            // 服务端其他语言参考 COS STS SDK ：https://github.com/tencentyun/qcloud-cos-sts-sdk
            // STS 详细文档指引看：https://cloud.tencent.com/document/product/436/14048

            // api({
            //   url: '/admin/admin/media/uploadCosTemporaryAuth',
            //   data: {
            //     type: 'video',
            //     file_name: that.fileList[0].name,
            //   },
            //   method: 'post',
            // }).then(({ data }) => {
            const {
              bucket_id,
              region,
              tmp_id,
              tmp_key,
              tmp_token,
              expire_in,
              title,
              dir,
              file_ext,
              key,
              start_time,
            } = that.data

            console.log({
              TmpSecretId: tmp_id,
              TmpSecretKey: tmp_key,
              SecurityToken: tmp_token,
              // 建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
              StartTime: start_time, // 时间戳，单位秒，如：1580000000
              // getAuthorization callback params "ExpiredTime" should be 10 digits
              ExpiredTime: expire_in, // 时间戳，单位秒，如：1580000000
            })

            callback({
              TmpSecretId: tmp_id,
              TmpSecretKey: tmp_key,
              SecurityToken: tmp_token,
              // 建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
              StartTime: start_time, // 时间戳，单位秒，如：1580000000
              // getAuthorization callback params "ExpiredTime" should be 10 digits
              // ExpiredTime: Number((expire_in.toString() + '0000000000').substr(0, 10)), // 时间戳，单位秒，如：1580000000
              ExpiredTime: start_time + expire_in, // 时间戳，单位秒，如：1580000000
            })

            // that.data = data;
            // })
          },
        })

        // 接下来可以通过 cos 实例调用 COS 请求。
        cos.uploadFile(
          {
            Bucket: this.data.bucket_id /* 填入您自己的存储桶，必须字段 */,
            Region:
              this.data.region /* 存储桶所在地域，例如ap-beijing，必须字段 */,
            Key: this.data
              .key /* 存储在桶里的对象键（例如1.jpg，a/b/test.txt），必须字段 */,
            Body: this.fileList[0]
              .raw /* 必须，上传文件对象，可以是input[type="file"]标签选择本地文件后得到的file对象 */,
            SliceSize:
              1024 *
              1024 *
              5 /* 触发分块上传的阈值，超过5MB使用分块上传，非必须 */,
            onTaskReady: function (taskId) {
              /* 非必须 */
              console.log(taskId)
            },
            onProgress: function (progressData) {
              /* 非必须 */
              console.log(JSON.stringify(progressData))

              const { percent } = progressData // percent 范围从 0 ~ 1，有小数

              // 来源：https://www.cnblogs.com/ygunoil/p/12468816.html
              that.uploadingFile.onProgress({ percent: percent * 100 }) // 范围从 0 ~ 100
            },
            onFileFinish: function (err, data, options) {
              /* 非必须 */
              console.log(options.Key + '上传' + (err ? '失败' : '完成'))
              console.log(data, 'data')

              if (!err) {
                const eTag = JSON.parse(data.ETag)

                api({
                  url: '/admin/admin/media/saveCosUploadFile',
                  method: 'post',
                  data: {
                    // 本回调获取
                    location: data.Location,
                    etag: eTag,
                    // 上个接口返回的
                    dir: that.data.dir,
                    file_ext: that.data.file_ext,
                    // 选文件的时候获取
                    type: that.currentType,
                    title: that.currentFile.name,
                    file_size: that.currentFile.size,
                  },
                })
                  .then(({ data }) => {
                    /**
                   * 数据示例：
                   *         "type": 3,
                   "pid": 0,
                   "description": null,
                   "title": "Free_Test_Data_2MB_MP4.mp4",
                   "config": {
            "thumbnail": "https:\/\/testsaasres.shetuan.pro\/https%3A\/testsaasres.shetuan.pro\/cos\/qXV4g30AvmQRawYK\/videos\/20240520\/86c13271f8eb92b263ef9c7fd8084ada.jpg",
            "duration": 55
        },
                   "create_time": 1716176882,
                   "file_size": 2100335,
                   "url": "https:\/\/testsaasres.shetuan.pro\/cos\/qXV4g30AvmQRawYK\/videos\/20240520\/86c13271f8eb92b263ef9c7fd8084ada.mp4",
                   "etag": null,
                   "res_server": "tencent-cos",
                   "id": "10968"
                   */
                    console.log(data)
                    that.currentFile.url = data.url
                    that.currentFile.status = 'success'
                    // that.uploadingFile.onSuccess() // 此行为会向 fileList 追加一条数据，导致与 :on-change 回调冲突

                    callback(data) // {type, title, url, pid}
                  })
                  .catch(() => {})
              }

              // Error: CORS blocked or network error
            },
            // 支持自定义headers 非必须
            // Headers: {
            //   'x-cos-meta-test': 123
            // },
          },
          function (err, data) {
            console.log(err || data)
          }
        )
      } catch (err) {
        console.log(err)
      }
    },

    uploadCos() {},

    handleRemove() {},

    beforeUpload() {},

    customUpload(file) {
      console.log('custom upload')
      this.uploadingFile = file // 为了更新进度条

      /**
       * 返回示例：
       * {
        "bucket_id": "testsaas-1303247975",
        "region": "ap-shanghai",
        "tmp_id": "AKIDScO9DHzDWUkTS2xiNxYxhhObLnW7qBol66KXcYEhi3-vSX2MwT3ipwZmgMf3ZVKr",
        "tmp_key": "Q4oy9qhhMhByGFwL5VfQjgPIqPdStOnkP4bMTLq33U8=",
        "tmp_token": "0zj3ApsWXvVQ7OZpDGudgaLlg9P8Gnua16ac6e910b2eca8bb0985cbf621702b5N1Y5em7yxO11wWemgGa7z65bxIdYf6sc8zD_vDtb6az4zGKJUzlWCJYlKl1sUaD36hRUdNtJ2bsHxMbGPrzYMVsFZShNKQ4_Yaf1E9JQzP9stTUWiFcnxZbrrxlxhXrl9WcDQALsXOcETB4qaLUBZQIEEUAHX4xWB911rrUXd1B3I_56Vnbkl2UzSLsnr2NUrcygEe3BeqFDs8i-u0rJKOgIJsuyT0_Y2jZiAIfW4ee2vWKbI2ny_lBu-fffVkdQrseRee_C0j5DVGZX3qyBI9b8FBi1rVx8vIWojEsTq2soz9XIsemn8ljTlvaY1Mkcg6Dog6izR0ST06JgfWcdNQXwFrfsLQVoThGBBW2LCuBD1HGXcT2J0QdKrD8jjuX6mCKvNt3M0k3zZ-8DlXZ5YdcP6yTMLADIpbU6JixHUfxMU2xylqM7bP8bOxYgK4LQ5F_qV3KDCchCRofxe_d6DDQSUZhKBlJHVPjzOeuqr5phvGFgkXB29nimBcDO_LuDVfsUTTz7QM0CNkPkWFOvOe5RCyMc5BOLmVQcjYQ14LeBo5s3B2Z1dZY4_aXgwYeuUCZrqpnwH5BjrnU3FmK7nEOMvwgG6y54uGA3MEgs_RivKaP2Pjs2haTAJvHYpf8XJsnB5t8KopaJ68jRQLFjAA",
        "expire_in": 1800,
        "title": "sample-file-quad-hd.mp4",
        "dir": "cos\/qXV4g30AvmQRawYK\/videos",
        "file_ext": "mp4",
        "key": "cos\/qXV4g30AvmQRawYK\/videos\/20240517\/03b19bfe57926d5b6e080044d27e1eda.mp4"
    }
       */
      this.fetchCosConfiguration((data) => {
        this.readyForCos(data)
      })
      // this.readyForCos()
    },

    afterInputFiles(file, fileList) {
      /**
       * file:
       * {name, percentage, raw, size, status, uid}
       */
      // console.log(file) // 当前的一个文件对象
      // console.log(fileList) // 累计选择的文件列表
      this.fileList.push({ ...file, url: '' }) // url 为自己加的属性，上传到媒体库后返回
    },
  },
}
</script>

<style lang="scss" scoped>
.page {
  min-height: 100vh;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
</style>
